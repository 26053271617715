import styled from "styled-components";
import React from "react";
import { Col, Row, Container } from "react-bootstrap";
import Styled from "styled-components";
import Canvas from '../components/canvas/Canvas';

const StyledDiv = styled.div`
  background-color: #1a1818;
  color: #ffffff;
  text-align: center;
`;

const StyledHeading = Styled.h2`
    color: #ffffff;
    font-size: 24px;
    line-height: 0.8;
    margin-bottom: 0;
    font-family: "Goudy";
`;

function Privacy() {
  return (
    <StyledDiv>
  	  <Canvas />
    </StyledDiv>
  );
}

export default Privacy;
