import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Profile from '../profile/Profile';

export default class Menu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            open: false
        }
    }

    componentDidMount() {

        this.setState({
            loaded: true
        })

    }

    toggle() {

        this.setState(prevState => ({
            open: !prevState.open
        }));
    }

    render() {
        return (
            <div className={`menu ${this.state.open ? 'active' : ''}`}>
                <a onClick={this.toggle.bind(this)} className={`menu-button ${this.state.open ? 'is-active' : ''} `}>
                    <span className="burger-icon"></span>
                </a>
                <div className={`menu-body ${this.state.open ? '' : 'collapse'}`}>
                    <Profile />
                </div>
            </div>
        )
    }
}
