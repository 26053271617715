import React, { Component } from "react";

export default class Award extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render(props) {

        return (
            <>
              <div className="award">
                <h2>{this.props.title}</h2>
                <h5>{this.props.sub}</h5>
                <p>{this.props.body} -- <span><i>{this.props.date}</i></span></p>
              </div>
            </>
        );
    }
}