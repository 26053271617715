import React, { useState } from "react";
import Context from "./context";

const Provider = ({ children }) => {
  const [state, setState] = useState({
    volume: 0
  });

  const updateVolume = (volume) => {
    setState({ ...state, volume });
  };

  return (
    <Context.Provider
      value={{
        state,
        updateVolume
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Provider;
