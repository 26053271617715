import React from "react";
import Award from "./Award";

class Profile extends React.Component {
	render() {
		return (
			<>
				<div className="content-block single-column">
					<div className="centred">
						<div className="content-wrap">
							<h1>Jono Casley<br />CTO<br />whiteGREY</h1>
						</div>
						<div className="content-wrap text-content">
							<p>Hi, I'm Jono a technical advertising professional from the UK with eighteen years digital development experience with a strong creative focus. I specialise in developing and exploring technical solutions for my clients, weaving design and interactivity into compelling narratives, which speak to the right people. I thrive on the opportunity to work within an innovative and creative environment where I can utilise and expand on my technical experience, as well as take advantage of mentorship. Allowing me to continuously evolve and learn efficient application development methodologies and techniques.</p>
						</div>
						<div className="content-wrap text-content">
							<h4>Contact</h4>
							<p>For all freelance opportunities or portfolio requests, please connect with me through <a target="_parent" href="https://www.linkedin.com/in/jono-c-8178808/">LinkedIn</a></p>
						</div>
						<div className="content-wrap">
							<h4>Awarded Work</h4>
							<Award
								title="Canne Lion - Gold 2023"
								sub="Most Beautiful Sound"
								body="Issued by Canne Lion"
								date="Nov 2023"
							/>
							<Award
								title="CLIO Health 4 x"
								sub="Most Beautiful Sound"
								body="Issued by CLIO"
								date="Nov 2023"
							/>
							<Award
								title="LIA 5 x"
								sub="Most Beautiful Sound"
								body="Issued by LIA"
								date="Nov 2023"
							/>
							<Award
								title="AFR Most Innovative Companies 2023"
								sub="Most Innovative Media & Marketing Company"
								body="Issued by Most Innovative Media & Marketing Company"
								date="Jan 2023"
							/>
							<Award
								title="AFR Most Innovative Companies 2023"
								sub="Most Innovative Small Business in Australia"
								body="Issued by Most Innovative Small Business in Australia"
								date="Jan 2023"
							/>
							<Award
								title="Fast Company World Changing Awards 2023 - Finalist ‘Apps’"
								sub="Mammoth"
								body="Issued by Fast Company"
								date="Jan 2023"
							/>
							<Award
								title="Fast Company World Changing Awards 2023 - Finalist ‘Nature’"
								sub="Mammoth"
								body="Issued by Fast Company"
								date="Jan 2023"
							/>
							<Award
								title="FWA Judge 2021"
								sub="FWA"
								body="Issued by FWA"
								date="Jun 2021"
							/>
							<Award
								title="The Siren Awards 2019"
								sub="DrinkWise: theInternetRemembers.com.au"
								body="Issued by The Siren Awards"
								date="Jan 2019"
							/>
							<Award
								title="FWA Award - FWA Of The Day 2019"
								sub="Krispy Kreme: Throwback Party"
								body="Issued by FWA"
								date="Jan 2019"
							/>
							<Award
								title="AWWWARDS - Games and Apps 2019"
								sub="Krispy Kreme: Throwback Party"
								body="Issued by AWWWARDS"
								date="Jan 2019"
							/>
							<Award
								title="Effie - Gold x2 2019"
								sub="NRMA Insurance: Home Insurance for Koalas"
								body="Issued by Effie"
								date="Jan 2019"
							/>
							<Award
								title="AWARD - Bronze x3 2019"
								sub="NRMA Insurance: Home Insurance for Koalas"
								body="Issued by AWARD"
								date="Jan 2019"
							/>
							<Award
								title="D&AD Awards - Wood Pencil 2019"
								sub="UNICEF: A Minute of Your Time"
								body="Digital Marketing / Campaign Websites"
								date="Jan 2019"
							/>
							<Award
								title="Effie - Most Original Thinking 2018"
								sub="Hello In Elephant"
								body="Issued by Effie"
								date="Jan 2018"
							/>
							<Award
								title="Clio - Bronze 2018"
								sub="Hello In Elephant"
								body="Issued by Clio"
								date="Jan 2018"
							/>
							<Award
								title="The One Show - Mobile Campaign 2018"
								sub="UNICEF: A Minute of Your Time"
								body="Issued by The One Show"
								date="Jan 2018"
							/>
							<Award
								title="AWARD Awards - Silver - Innovation 2018"
								sub="UNICEF: A Minute of Your Time"
								body="Issued by AWARD Awards"
								date="Jan 2018"
							/>
							<Award
								title="Canne Lion - Silver 2017"
								sub="Hello In Elephant"
								body="Issued by Canne Lion"
								date="Jan 2017"
							/>
							<Award
								title="Effie 2016"
								sub="Telstra: Programatic Multi Channel Comms"
								body="Issued by Effie"
								date="Jan 2016"
							/>
							<Award
								title="The Big Chip Awards: International Innovation 2011"
								sub="HTML vs Flash PONG"
								body="Issued by BigChip"
								date="Jan 2011"
							/>
							<Award
								title="The Big Chip Awards: Seaworld World: Interactive Maps 2011"
								sub="Issued by BigChip"
								body="Associated with Code Computerlove"
								date="Jan 2011"
							/>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default Profile;