import "./App.scss";
import { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { useSpring, animated } from '@react-spring/web';
import { Helmet } from "react-helmet";

import styled from "styled-components";
import Home from "./pages/home";
import Logo from "./components/logo/logo";
import Menu from './components/menu/Menu'

const Container = styled.div`
  text-align: center;
  /* position: relative;
  height: 100vh;
  background: #000; */
`;

const App = () => {

  useEffect(() => {
    const lockOrientation = async () => {
      try {
        // More robust feature detection
        if (typeof window !== 'undefined' && window.screen && window.screen.orientation && 'lock' in window.screen.orientation) {
          await window.screen.orientation.lock('portrait-primary');
          console.log('Orientation locked');
        } else {
          console.log('Screen Orientation API is not available in this browser.');
        }
      } catch (error) {
        console.error('Orientation lock not enabled:', error);
      }
    };

    lockOrientation();

    // Cleanup function with enhanced feature detection
    return () => {
      if (typeof window !== 'undefined' && window.screen && window.screen.orientation && 'unlock' in window.screen.orientation) {
        window.screen.orientation.unlock();
      }
    };
  }, []);

  const location = useLocation();

  const introStyles = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    config: { duration: 1000 }
  });

  return (
    <Container>
      <Helmet>
        <meta name="keywords" content={process.env.REACT_APP_META_KEYWORDS} />
        <meta name="description" content={process.env.REACT_APP_META_DESCRIPTION} />
      </Helmet>
      <animated.div
        style={introStyles}>
        <Logo />
        <Menu />
        <Routes location={location} key={location.pathname}>
          <Route path='/' element={<Home />} />
          {/* <Route path='/essays' element={<Essays />} /> */}
        </Routes>
      </animated.div>
    </Container>
  );
};

export default App;