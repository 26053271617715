import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import logo from './logo.png';
import { media } from "../media/media";

const Header = styled.div`
    height: 82px;
    position: fixed;
    z-index: 1;
`;

const Brand = styled.div`
    position: absolute;
    left: 5px;
    top: 5px;
    text-align: left;
    padding: 0px;
`;

const RR = styled.img`

    @media ${media.mobileS} {
        height: 50px;
        margin-top: 10px;
    }

    @media ${media.tablet} {
        height: 50px;
    }

    @media ${media.laptop} {
        height: 50px;
        margin-top: 0;
    }
`;


export default function Logo() {
  return (
    <Header>
        <Brand>
            <Link to="/">
                <RR src={logo}  alt='Craveware logo' />
            </Link>
        </Brand>
    </Header>
  );
}
