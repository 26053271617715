import React, { Component } from "react";
import * as THREE from "three";
import Perlin from './Perlin';
import Styled from "styled-components";

const StyledDiv = Styled.div`
    width:100vw;
    height:100vh;
`;


export default class Canvas extends Component {

    constructor(props) {
        super(props);

    }

    componentDidMount() {

        const near = 10;
        const far = 170;
        this.pixelRatio = window.devicePixelRatio;
        this.onResize = this.onResize.bind(this);
        this.onMousemove = this.onMousemove.bind(this);
        this.onTouch = this.onTouch.bind(this);

        window.addEventListener('resize', this.onResize);
        window.addEventListener('mousemove', this.onMousemove);


        //orbit camera
        this.mouse = new THREE.Vector2();
        this.target = new THREE.Vector2();

        this.height = window.innerHeight;
        this.width = window.innerWidth;

        this.width = this.width * this.pixelRatio | 0;
        this.height = this.height * this.pixelRatio | 0;
        this.windowHalf = new THREE.Vector2(this.width / 2, this.height / 2);

        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(
            75,
            window.innerWidth / window.innerHeight,
            near, far
        );

        this.camera.lookAt(this.scene.position);

        //this.scene.background = new THREE.Color(0xf1f1f1);
        this.scene.background = new THREE.Color(0x000000);


        this.scene.fog = new THREE.Fog(0x000000, near, far);
        this.renderer = new THREE.WebGLRenderer();
   
        this.renderer.setSize(this.width, this.height, false); 

        this.canvas.appendChild(this.renderer.domElement);
        document.body.addEventListener('touchstart', this.onTouch, false);
        document.body.addEventListener('touchmove', this.onTouch, false);

        /* Setup Star Field */

        let vertices = [];
        let sizes = new Float32Array(1000);
        let alphas = new Float32Array(1000);

        var textureLoader = new THREE.TextureLoader();
        var sprite1 = textureLoader.load('images/three/point.png');

        for (var i = 0; i < 420; i++) {

            var x = THREE.MathUtils.randFloatSpread(100);
            var y = THREE.MathUtils.randFloatSpread(100);
            var z = THREE.MathUtils.randFloatSpread(100);

            let rand_sphere = this.randomSpherePoint(0, 0, 0, 100);
             x = rand_sphere[0];
             y = rand_sphere[1];
             z = rand_sphere[2];

            vertices.push(x, y, z);

            sizes[i] = 100;
            alphas[i] = 1;
        }

        var geometrys = new THREE.BufferGeometry();
        geometrys.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
        geometrys.setAttribute('size', new THREE.BufferAttribute(sizes, 1));
        geometrys.setAttribute('alpha', new THREE.BufferAttribute(alphas, 1));

        var materials = new THREE.PointsMaterial({ size: 1, map: sprite1, blending: THREE.AdditiveBlending, depthTest: true, transparent: true });
        var points = new THREE.Points(geometrys, materials);

        this.scene.add(points);

        /* Setup Perlin */

        var perlin = new Perlin(100);
        let xoff = 0;
        let yoff = 0;

        /* Update Render */

        let uniforms = {
            colorB: { type: 'vec3', value: new THREE.Color(0x000000) },
            colorA: { type: 'vec3', value: new THREE.Color(0x1a1a1a) },
            fogColor: { type: "c", value: this.scene.fog.color },
            fogNear: { type: "f", value: this.scene.fog.near },
            fogFar: { type: "f", value: this.scene.fog.far }
        }

        /* Shader */
        let material = new THREE.ShaderMaterial({
            uniforms: uniforms,
            fragmentShader: this.fragmentShader(),
            vertexShader: this.vertexShader(),
            wireframe: false,
            fog: true
        })

        /* Basic Mesh */
        const materialfloor = new THREE.MeshBasicMaterial({
            color: 0x000000,
            wireframe: false,
            side: THREE.DoubleSide
        });

        //create the plane geometry
        var geometry = new THREE.PlaneGeometry(250, 250, 100, 100);
        geometry.verticesNeedUpdate = true;
        geometry.elementsNeedUpdate = true;
        geometry.morphTargetsNeedUpdate = true;
        geometry.uvsNeedUpdate = true;
        geometry.normalsNeedUpdate = true;
        geometry.colorsNeedUpdate = true;
        geometry.tangentsNeedUpdate = true;

        geometry.computeFaceNormals();
        geometry.computeVertexNormals();

        var ground = new THREE.Mesh(geometry, material);
        ground.rotation.x = 1.9;
        ground.rotation.z = -0.8;
        ground.rotation.y = 0.03;
        ground.position.z = -50;

        this.scene.add(ground);

        var geometry2 = new THREE.PlaneGeometry(250, 250, 100, 100);

        var ground2 = new THREE.Mesh(geometry2, materialfloor);
        ground2.rotation.x = 1.9;
        ground2.rotation.z = -0.8;
        ground2.rotation.y = 0.03;
        ground2.position.z = -25;
        ground2.position.y = -10;
        this.scene.add(ground2);

        var sphere_geometry = new THREE.SphereGeometry(20, 30, 50);
        var sphere_material = new THREE.MeshPhongMaterial({
            transparent: true,
            opacity: 1,
            color: 0x000,
            emissive: 0x0,
            specular: 0x111111,
            flatShading: false,
            shininess: 50,
            fog: true,
            wireframe: false
        });

        var sphere = new THREE.Mesh(sphere_geometry, sphere_material);
        this.scene.add(sphere);
        sphere.position.z = -50;
        sphere.position.y = 10;

        
        var outlineMaterial1 = new THREE.MeshBasicMaterial( { color: 0xffffff, side: THREE.BackSide } );
        var outlineMesh1 = new THREE.Mesh( sphere_geometry, outlineMaterial1 );
        outlineMesh1.position.x = sphere.position.x;
        outlineMesh1.position.y = sphere.position.y;
        outlineMesh1.position.z = sphere.position.z;
        outlineMesh1.scale.multiplyScalar(1.005);
        this.scene.add( outlineMesh1 );

        // SPOTLIGHT
        var light = new THREE.PointLight(0x111111, 30, 110);
        light.position.set(50, 50, -10);
        this.scene.add(light);

        /* End Render */

        this.camera.position.z = 15;

        var center = new THREE.Vector3(0, 0, 0);

        var animate = function (ts) {

            requestAnimationFrame(animate);

            geometry.vertices.forEach(v => {

                //perfect chop
                v.z = 2.5 * perlin.noise(
                    ((v.x) + xoff) / 2,
                    ((v.y) + yoff * 1.6)
                );

                var dist = new THREE.Vector3(v.x, v.y / 3, v.z).sub(center);
                var size = 10.0;
                var magnitude = 4;
                v.z += (Math.sin(dist.length() / -size + (ts / 500)) * magnitude) / 2;

                //speed
                xoff += 0.000001;
                yoff += 0.000001;
            })

            var dist = new THREE.Vector3(sphere.position.x, sphere.position.y / 3, sphere.position.z).sub(center);
            var size = 10.0;
            var magnitude = 4;
            sphere.position.y = 5 + (Math.sin(dist.length() - size + (ts / 500)) * magnitude);
            outlineMesh1.position.y = sphere.position.y;

            geometry.verticesNeedUpdate = true;
            geometry.computeFaceNormals();
            geometry.computeVertexNormals();

            this.target.x = (1 - this.mouse.x) * 0.0002;
            this.target.y = (1 - this.mouse.y) * 0.0002;
            this.camera.rotation.x += 0.01 * (this.target.y - this.camera.rotation.x);
            this.camera.rotation.y += 0.01 * (this.target.x - this.camera.rotation.y);
            this.renderer.render(this.scene, this.camera);

        }.bind(this);

        animate();

    }

    vertexShader() {
        return `
        varying vec3 vUv; 
        varying vec4 modelViewPosition; 
        varying vec3 vecNormal;

        void main() {
            vUv = position; 
            vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
            vecNormal = (modelViewMatrix * vec4(normal, 0.0)).xyz;
            gl_Position = projectionMatrix * modelViewPosition; 
        }
    `
    }

    fragmentShader() {
        return `
        uniform vec3 colorA; 
        uniform vec3 colorB; 
        varying vec3 vUv;
        uniform vec3 fogColor;
        uniform float fogNear;
        uniform float fogFar;

        void main() {
            gl_FragColor = vec4(mix(colorA, colorB, vUv.z), 1.0);
             #ifdef USE_FOG
                #ifdef USE_LOGDEPTHBUF_EXT
                    float depth = gl_FragDepthEXT / gl_FragCoord.w;
                #else
                    float depth = gl_FragCoord.z / gl_FragCoord.w;
                #endif
                float fogFactor = smoothstep( fogNear, fogFar, depth );
                gl_FragColor.rgb = mix( gl_FragColor.rgb, fogColor, fogFactor );
            #endif
        }
    `
    }

    randomSpherePoint(x0, y0, z0, radius) {
        var u = Math.random();
        var v = Math.random();
        var theta = 2 * Math.PI * u;
        var phi = Math.acos(2 * v - 1);
        var x = x0 + (radius * Math.sin(phi) * Math.cos(theta));
        var y = y0 + (radius * Math.sin(phi) * Math.sin(theta));
        var z = z0 + (radius * Math.cos(phi));
        return [x, y, z];
    }


    onResize() {
        this.height = window.innerHeight;
        this.width = window.innerWidth;
    }

    onMousemove(event) {
        this.mouse.x = (event.clientX - this.windowHalf.x);
        this.mouse.y = (event.clientY - this.windowHalf.x);
    }

    onTouch(event) {
        this.mouse.x = (event.changedTouches[0].clientX / this.width) * 2 - 1;
        this.mouse.y = -(event.changedTouches[0].clientY  / this.height) * 2 + 1;
    }

    show() {

    }

    hide() {

    }

    render() {
        return (
            <>
                <StyledDiv ref={ref => (this.canvas = ref)} />
            </>
        );
    }
}